import React from 'react';
import { CardContent, CardMedia, Typography, Button, Box, Paper } from '@mui/material';
import { styled } from '@mui/system';

// Styling for the card with a flipping effect
const FlippingCard = styled(Paper)(({ theme }) => ({
  position: 'relative',
  width: '300px',
  height: '450px',
  perspective: '1000px',
  borderRadius: '16px',
  overflow: 'hidden',
  boxShadow: theme.shadows[5],
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  '&:hover .flip': {
    transform: 'rotateY(180deg)',
  },
}));

const FlipInner = styled(Box)({
  position: 'relative',
  width: '100%',
  height: '100%',
  transition: 'transform 0.6s',
  transformStyle: 'preserve-3d',
});

const FlipFront = styled(CardMedia)({
  position: 'absolute',
  width: '100%',
  height: '100%',
  backfaceVisibility: 'hidden',
});

const FlipBack = styled(CardContent)({
  position: 'absolute',
  width: '100%',
  height: '100%',
  backgroundColor: '#f5f5f5',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  textAlign: 'center',
  transform: 'rotateY(180deg)',
  backfaceVisibility: 'hidden',
  padding: '16px',
});

const ButtonContainer = styled(Box)({
  position: 'flex',
  bottom: '16px',
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
});

const StyledButton = styled(Button)({
  width: '80%',
  maxWidth: '200px',
});

const ProductCard = ({ image, title, description, buttonText, url }) => {
  return (
    <FlippingCard elevation={3}>
      <FlipInner className="flip">
        <FlipFront component="img" src={image} alt={title} />
        <FlipBack>
          <Typography variant="h5">{title}</Typography>
          <Typography variant="body2" color="text.secondary" sx={{ mt: 2 }}>
            {description}
          </Typography>
        </FlipBack>
      </FlipInner>

      <ButtonContainer>
        <StyledButton 
          variant="contained" 
          color="primary" 
          href={url} 
          target="_blank" 
          rel="noopener noreferrer"
        >
          {buttonText}
        </StyledButton>
      </ButtonContainer>
    </FlippingCard>
  );
};

export default ProductCard;
