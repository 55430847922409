import './App.css';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./pages/Home";
import Layout from "./pages/Layout";
import Contact from "./pages/Contact";
import Patients from "./pages/Clients";
import Services from "./pages/Services";
import FirstResponders from "./pages/FirstResponders";
import ProviderListPage from './pages/providers/ProviderListPage';
import ProviderPage from './pages/providers/ProviderPage';
import PrivacyPolicy from './pages/PrivacyPolicy';
import ScrollToTop from './components/scrollToTop.js';
import ProductPage from "./pages/Productpage.js";

const App = () => {
  return (
    <BrowserRouter>
    <ScrollToTop />
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Home />} />
          <Route path="/providers" element={<ProviderListPage />} />
          <Route path="/providers/:id" element={<ProviderPage />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/patients" element={<Patients />} />
          <Route path="/services" element={<Services />} />
          <Route path="/first-responders" element={<FirstResponders />} />
          <Route path="/clients" element={<Patients />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/products" element={<ProductPage />} />
          <Route path="*" element={<h1>Page Not Found</h1>} />  {/* Fallback */}
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;