import React from 'react';
import { Box, Typography, Paper} from '@mui/material';

const HeadshotAndDescription = ({ imageSrc, name, description }) => {
  return (
    <Paper elevation={3} sx={{ p: 2, borderRadius: "15px", textAlign: "center" }}>
      <Box
        sx={{
          width: '100%',
          height: '300px',
          overflow: 'hidden',
          borderRadius: '15px 15px 0 0',
        }}
      >
        <img
          src={imageSrc}
          alt={name}
          style={{
            width: '100%',
            height: '115%',
            objectFit: 'cover',
          }}
        />
      </Box>
      <Typography variant="h6" gutterBottom sx={{ mt: 2 }}>
        {name}
      </Typography>
      <Box sx={{ mt: 2 }}>
        {description}
      </Box>
    </Paper>
  );
};

export default HeadshotAndDescription;