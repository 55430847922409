import React from 'react';
import { Grid, Container,Paper, Typography,Button } from '@mui/material';
import ProductCard from '../components/ProductCard';   

const ProductPage = () => {
  const products = [
    {
      image: process.env.PUBLIC_URL + '/assets/images/Supplements/DailyEssentials.jpg',
      title: 'Daily Essentials',
      description: 'Provides daily nutrients and minerals! Promotes skeletal, hormonal, and cardiovascular health. Increases energy and maintains healthy serotonin levels! Vegetarian and Gluten free!',
      buttonText: 'Buy Now',
      url: 'https://www.nutrametrix.com/product/nutrametrix-isotonix-daily-essentials-packets/?id=5253595&idType=product', // Add the external URL here
    },
    {
      image: process.env.PUBLIC_URL + '/assets/images/Supplements/VitD.jpg',
      title: 'Vitamin D With K2',
      description: 'Supports vascular health and calcium utilization. Vitamin D plays an important role in bone health, heart health and immune support, while working with vitamin K to support normal absorption of calcium and promote healthy arteries. Vegetarian and Gluten free!',
      buttonText: 'Buy Now',
      url: 'https://www.nutrametrix.com/product/nutrametrix-isotonix-vitamin-d-with-k2---single-bottle-30-servings/?id=13972&idType=sku', // Add the external URL here
    },
    {
        image: process.env.PUBLIC_URL + '/assets/images/Supplements/Digestive.jpg',
        title: 'Digestive Enzymes With Probiotics',
        description: 'Promotes a healthy digestive tract, helps maintain healthy cholesterol, aids in nutrient absorption and supports healthy skin. Vegetarian and Gluten free.',
        buttonText: 'Buy Now',
        url: 'https://www.nutrametrix.com/product/nutrametrix-isotonix-digestive-enzymes-with-probiotics/?id=1329811&idType=product', // Add the external URL here
      },
      {
        image: process.env.PUBLIC_URL + '/assets/images/Supplements/turnUp.jpg',
        title: 'Turn Up',
        description: 'Increases energy and stamina. Helps improve mental focus and promotes cognitive health. Promotes normal regulation of hormones and enzymes. Vegetarian and gluten free.',
        buttonText: 'Buy Now',
       url: 'https://www.nutrametrix.com/product/nutrametrix-isotonix-essentials-turn-up/?id=5797510', // Add the external URL here
      },
      {
        image: process.env.PUBLIC_URL + '/assets/images/Supplements/turnDown.jpg',
        title: 'Turn Down',
        description: 'Promotes sleep while supporting healthy sleep quality. Helps to release mental tension and clear the mind. May enhance mood while promoting calmness and relaxation.  Provides strong antioxidant protection and normal regulation of hormones and enzymes. Vegetarian and gluten free. ',
        buttonText: 'Buy Now',
        url: 'https://www.nutrametrix.com/product/nutrametrix-isotonix-essentials-turn-down/?id=5797508&idType=product', // Add the external URL here
      },
      {
        image: process.env.PUBLIC_URL + '/assets/images/Supplements/Bliss.jpg',
        title: 'Bliss: Anti-Stress',
        description: 'May help to minimize certain stress related issues! Helps to maintain healthy serotonin and dopamine levels. Supports normal HPA functions, healthy cortisol levels and thyroid functions. Vegetarian and gluten free! ',
        buttonText: 'Buy Now',
        url: 'https://www.nutrametrix.com/product/nutrametrix-blissand174-anti-stress-formula/?id=1329824&idType=product', // Add the external URL here
      },
  ];

  return (
    
    
    <Container sx={{ mt: 5 }}>
    <Paper elevation={18} sx={{ p: 2, mb: 6, textAlign: "center", borderRadius: "25px", backgroundColor: '#4f5a5e' }}>
          <Typography variant="h3" align='center' color='common.white'>
            Supplements
          </Typography>
          <Typography variant="h5" align='center' color='common.white'>
            Here at Northland Psychological Services, we offer a range of curated supplement options designed to help you! 
          </Typography>
          <Typography variant="h5" align='center' color='common.white'>
            View our line below!
          </Typography>
          <Button
                        variant="contained"
                        sx={{
                          mt: 2,
                          backgroundColor: 'black',
                          color: 'white',
                          '&:hover': { backgroundColor: 'black', color: 'white' }
                        }}
                        target='_blank'
                        href="https://www.nutrametrix.com/northlandpsychological"
                      >
                        View all supplements
                      </Button>

        </Paper>
      <Grid container spacing={4} justifyContent="center">
        {products.map((product, index) => (
          <Grid item key={index}>
            <ProductCard {...product} />
          </Grid>
        ))}
      </Grid>
    </Container>
  );
};

export default ProductPage;
