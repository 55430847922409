import React from 'react';
import { Container, Grid, Typography, Box, Paper, Button } from '@mui/material';
import HeadshotAndDescription from '../components/providers/ClientsPhotos';
import PageTitle from '../components/PageTitle';

const Patients = () => {
  return (
    <Container maxWidth="xl" sx={{ overflowX: 'hidden', padding: 0 }}>
      <PageTitle
        title="Clients"
        description={
          <>
            Welcome to Northland Psychological Services' Clients Page! You will find Doxy links for telehealth below your respective provider and necessary documents further down!
            <Grid container justifyContent="center">
              <Grid item>
                <Button
                  variant="contained"
                  sx={{
                    mt: 2,
                    backgroundColor: 'black',
                    color: 'white',
                    '&:hover': { backgroundColor: 'black', color: 'white' }
                  }}
                  href="#forms"
                >
                  Forms
                </Button>
              </Grid>
              <Grid item>
                <Button
                  variant="contained"
                  sx={{
                    mt: 2,
                    backgroundColor: 'black',
                    color: 'white',
                    '&:hover': { backgroundColor: 'black', color: 'white' }
                  }}
                  href="https://pp-wfe-101.advancedmd.com/137030/account/logon" 
                  target='_blank'
                >
                  Patient Portal
                </Button>
              </Grid>
            </Grid>
          </>
        }
      />
      <Box sx={{ my: 4 }} >
        <Grid container>
          <Grid container spacing={{ xs: 12, lg: 3, md: 6 }}>
            <Grid item xs={12} lg={3} md={6}>
              <HeadshotAndDescription
                imageSrc='assets\images\northlandOfficePhotos\FinalHeadshots\Heather.png'
                name='Dr. Heather Rose-Carlson'
                description={
                  <a
                    href="https://doxy.me/drhrcarlson"
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ textDecoration: 'none' }}
                  >
                    <Button
                      variant="contained"
                      sx={{
                        mt: 2,
                        backgroundColor: 'black',
                        color: 'white',
                        '&:hover': { backgroundColor: 'black', color: 'white' },
                      }}
                    >
                      Telehealth
                    </Button>
                  </a>
                }
              />
            </Grid>

            <Grid item xs={12} lg={3} md={6}>
              <HeadshotAndDescription imageSrc='assets\images\northlandOfficePhotos\FinalHeadshots\Christina.png' name='Christina Finlo'
                description=<a
                  href="https://doxy.me/cfinlo"
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ textDecoration: 'none' }}
                >
                  <Button
                    variant="contained"
                    sx={{
                      mt: 2,
                      backgroundColor: 'black',
                      color: 'white',
                      '&:hover': { backgroundColor: 'black', color: 'white' },
                    }}
                  >
                    Telehealth
                  </Button>
                </a> />
            </Grid>
            <Grid item xs={12} lg={3} md={6}>
              <HeadshotAndDescription imageSrc='assets\images\northlandOfficePhotos\FinalHeadshots\Alex.png' name='Alex Ross'
                description=<a
                  href="https://doxy.me/arossnps"
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ textDecoration: 'none' }}
                >
                  <Button
                    variant="contained"
                    sx={{
                      mt: 2,
                      backgroundColor: 'black',
                      color: 'white',
                      '&:hover': { backgroundColor: 'black', color: 'white' },
                    }}
                  >
                    Telehealth
                  </Button>
                </a> />
            </Grid>
            <Grid item xs={12} lg={3} md={6}>
              <HeadshotAndDescription imageSrc='assets\images\northlandOfficePhotos\FinalHeadshots\Peggy.png' name='Peggy Romero'
                description=<a
                  href="https://doxy.me/PeggyRomero"
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ textDecoration: 'none' }}
                >
                  <Button
                    variant="contained"
                    sx={{
                      mt: 2,
                      backgroundColor: 'black',
                      color: 'white',
                      '&:hover': { backgroundColor: 'black', color: 'white' },
                    }}
                  >
                    Telehealth
                  </Button>
                </a> />
            </Grid>
            <Grid item xs={12} lg={3} md={6}>
              <HeadshotAndDescription imageSrc='assets\images\northlandOfficePhotos\FinalHeadshots\David.png' name='David Lindlbauer'
                description=<a
                  href="https://doxy.me/davidnps"
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ textDecoration: 'none' }}
                >
                  <Button
                    variant="contained"
                    sx={{
                      mt: 2,
                      backgroundColor: 'black',
                      color: 'white',
                      '&:hover': { backgroundColor: 'black', color: 'white' },
                    }}
                  >
                    Telehealth
                  </Button>
                </a> />
            </Grid>
            <Grid item xs={12} lg={3} md={6}>
              <HeadshotAndDescription imageSrc='assets\images\northlandOfficePhotos\FinalHeadshots\Rebecca.png' name='Rebecca Oachs'
                description=<a
                  href="https://doxy.me/roachs"
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ textDecoration: 'none' }}
                >
                  <Button
                    variant="contained"
                    sx={{
                      mt: 2,
                      backgroundColor: 'black',
                      color: 'white',
                      '&:hover': { backgroundColor: 'black', color: 'white' },
                    }}
                  >
                    Telehealth
                  </Button>
                </a> />
            </Grid>



            <Grid item xs={12} lg={3} md={6}>
              <HeadshotAndDescription imageSrc='assets\images\updatedpics\adamHeadshot.jpg' name='Adam Huot'
                description=<a
                  href="https://doxy.me/ahuot"
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ textDecoration: 'none' }}
                >
                  <Button
                    variant="contained"
                    sx={{
                      mt: 2,
                      backgroundColor: 'black',
                      color: 'white',
                      '&:hover': { backgroundColor: 'black', color: 'white' },
                    }}
                  >
                    Telehealth
                  </Button>
                </a> />
            </Grid>


            <Grid item xs={12} lg={3} md={6}>
              <HeadshotAndDescription imageSrc='assets\images\updatedpics\lindziHeadshot.jpg' name='Lindzi Campbell'
                description=<Button
                  variant="contained"
                  sx={{
                    mt: 2,
                    backgroundColor: 'black',
                    color: 'white',
                    '&:hover': { backgroundColor: 'black', color: 'white' }
                  }}
                  href="#"
                >
                  Telehealth
                </Button> />
            </Grid>
          </Grid>
        </Grid>
      </Box>


      <Paper elevation={18} sx={{ p: 2, mb: 2, textAlign: "center", borderRadius: "25px", backgroundColor: "#4f5a5e" }}>
        <Box id="forms">
          <Typography variant="h4" gutterBottom color="common.white">
            Forms
          </Typography>
          <Typography variant="body1" color="common.white">
            Here you will find all necessary forms!
          </Typography>


        </Box>
      </Paper>

      <Container maxWidth="lg">
        <Grid container spacing={4} justifyContent="center" alignItems="flex-start">
          <Grid item xs={12} md={6} sx={{ textAlign: "center", py: 3 }}>
            <Box sx={{ maxWidth: "100%", overflow: "hidden", borderRadius: "25px" }}>
              <Paper elevation={3} sx={{ p: 2, borderRadius: "15px" }}>
                <Typography variant="h6" gutterBottom>General Forms</Typography>
                <Grid container
                  direction="column"
                  spacing={2}
                  alignItems="stretch">
                  <Grid item>
                    <a
                      href="assets/HealthForms/ROI-NPS.pdf"
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{ textDecoration: 'none', width: '100%', display: 'block' }}
                    >
                      <Button
                        variant="contained"
                        fullWidth
                        sx={{
                          backgroundColor: 'black',
                          color: 'white',
                          '&:hover': { backgroundColor: 'black', color: 'white' },
                        }}
                      >
                        Release Of Information
                      </Button>
                    </a>
                  </Grid>
                  <Grid item>
                    <Button
                      variant="contained"
                      fullWidth
                      sx={{
                        backgroundColor: 'black',
                        color: 'white',
                        '&:hover': { backgroundColor: 'black', color: 'white' }
                      }}
                      href="/privacy-policy"
                    >
                      Privacy Policy
                    </Button>
                  </Grid>
                </Grid>
              </Paper>
            </Box>
          </Grid>

          <Grid item xs={12} md={6} sx={{ textAlign: "center", py: 3 }}>
            <Box sx={{ maxWidth: "100%", overflow: "hidden", borderRadius: "25px" }}>
              <Paper elevation={3} sx={{ p: 2, borderRadius: "15px" }}>
                <Typography variant="h6" gutterBottom>NHSC</Typography>
                <Typography variant="body1">National Health Service Corps forms</Typography>
                <Grid container
                  direction="column"
                  spacing={2}
                  alignItems="stretch">
                  <Grid item>
                    <Button
                      variant="contained"
                      fullWidth
                      sx={{
                        backgroundColor: 'black',
                        color: 'white',
                        '&:hover': { backgroundColor: 'black', color: 'white' }
                      }}
                      href="assets\HealthForms\Non-Discrimination Policy (1).docx"
                    >
                      Non-Discrimination Policy
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button
                      variant="contained"
                      fullWidth
                      sx={{
                        backgroundColor: 'black',
                        color: 'white',
                        '&:hover': { backgroundColor: 'black', color: 'white' }
                      }}
                      href="assets\HealthForms\Sliding Fee Application 2024.docx"
                    >
                      Discounted/Sliding Fee Application
                    </Button>
                  </Grid>
                  <Grid item>
                    <a
                      href="assets/HealthForms/Sliding Fee Policy 2024 NPS.pdf"
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{ textDecoration: 'none', width: '100%', display: 'block' }}
                    >
                      <Button
                        variant="contained"
                        fullWidth
                        sx={{
                          backgroundColor: 'black',
                          color: 'white',
                          '&:hover': { backgroundColor: 'black', color: 'white' },
                        }}
                      >
                        Discounted/Sliding Fee Policy
                      </Button>
                    </a>
                  </Grid>
                  <Grid item>
                    <a
                      href="assets\HealthForms\Sliding Fee Schedule 2024.pdf"
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{ textDecoration: 'none', width: '100%', display: 'block' }}
                    >
                      <Button
                        variant="contained"
                        fullWidth
                        sx={{
                          backgroundColor: 'black',
                          color: 'white',
                          '&:hover': { backgroundColor: 'black', color: 'white' },
                        }}
                      >
                        Discounted/Sliding Fee Scale Percentages
                      </Button>
                    </a>
                  </Grid>
                </Grid>
              </Paper>
            </Box>
          </Grid>


        </Grid>
      </Container>





    </Container>
  );
};

export default Patients;

