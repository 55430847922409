import React from 'react';
import { Box, Button, Typography, Paper, CardMedia, CardContent } from '@mui/material';
import { styled } from '@mui/system';

// Flipping card with responsive layout
const FlippingCard = styled(Paper)(({ theme }) => ({
  position: 'relative',
  width: '100%', // Take full width by default
  maxWidth: '400px', // Restrict max width
  height: '500px',
  perspective: '1000px',
  borderRadius: '16px',
  overflow: 'hidden',
  boxShadow: theme.shadows[5],
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  margin: '0 auto', // Center the card horizontally
  [theme.breakpoints.down('sm')]: {
    height: '450px', // Adjust height for smaller screens
  },
  '&:hover .flip': {
    transform: 'rotateY(180deg)',
  },
}));

const FlipInner = styled(Box)({
  position: 'relative',
  width: '100%',
  height: '100%',
  transition: 'transform 0.6s',
  transformStyle: 'preserve-3d',
});

const FlipFront = styled(Box)({
  position: 'absolute',
  width: '100%',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  padding: '16px',
  backfaceVisibility: 'hidden',
});

const JobImage = styled(CardMedia)({
  width: '100%',
  height: '60%',
  borderRadius: '8px',
  objectFit: 'cover',
  marginBottom: '8px',
});

const FlipBack = styled(CardContent)({
  position: 'absolute',
  width: '100%',
  height: '100%',
  backgroundColor: '#f5f5f5',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  textAlign: 'center',
  transform: 'rotateY(180deg)',
  backfaceVisibility: 'hidden',
  padding: '16px',
});

const ButtonContainer = styled(Box)({
  marginTop: '8px',
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
  padding: '16px',
  backgroundColor: '#fff',
  borderTop: '1px solid #ddd',
});

const StyledButton = styled(Button)({
  width: '80%',
  maxWidth: '250px',
});

const JobInfo = ({ header, image, description, email }) => {
  return (
    <FlippingCard elevation={3}>
      <FlipInner className="flip">
        {/* Front Side with Header and Image */}
        <FlipFront>
          <Typography variant="h5" align="center" gutterBottom>
            {header}
          </Typography>
          <JobImage component="img" src={image} alt={header} />
        </FlipFront>

        {/* Back Side with Job Description */}
        <FlipBack>
          <Typography variant="body1">{description}</Typography>
        </FlipBack>
      </FlipInner>

      {/* Apply Now Button at the Bottom */}
      <ButtonContainer>
        <StyledButton
          variant="contained"
          color="primary"
          href={`mailto:${email}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          Apply Now
        </StyledButton>
      </ButtonContainer>
    </FlippingCard>
  );
};

export default JobInfo;
